@use "sass:map";

@media screen and (min-width: map-get($breakpoints, "lg")),
  screen and (max-width: (map-get($breakpoints, "md") - 1)) {
  .is-visible-tablet-only {
    display: none !important;
  }
}

.text-truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
