.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.flex-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.is-full-width {
  width: 100%;
}

.is-full-height {
  height: 100%;
}

.is-vh-centered {
  align-items: center;
}
