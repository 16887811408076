@use "sass:map";

.isomer-card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  // Add bottom margin unless the card grid is the last item on the page
  &:not(:has(+ #related-content)) {
    margin-bottom: 1.5rem;
  }

  .isomer-card {
    float: left;
    flex-basis: calc((100% - 3rem) / 3);
    max-width: calc((100% - 3rem) / 3);
    flex-shrink: 0;
    border-width: 1px;
    border-style: solid;
    border-color: $border-light;
    text-decoration: none;
    margin-bottom: 0;

    &:hover {
      text-decoration: none;

      .isomer-card-body .isomer-card-link {
        color: $interaction-link-hover;
      }
    }

    // Stretch cards if there are only 2 cards
    &:nth-child(1):nth-last-child(2),
    &:nth-child(2):nth-last-child(1) {
      flex-basis: calc((100% - 1.5rem) / 2);
      max-width: calc((100% - 1.5rem) / 2);
    }

    // Stretch the card if there is only 1 card
    &:only-child {
      flex-basis: 100%;
      max-width: 100%;
    }

    // Collapse to 2 columns between md and xl
    @media screen and (max-width: map-get($breakpoints, "xl")) {
      flex-basis: calc((100% - 1.5rem) / 2);
      max-width: calc((100% - 1.5rem) / 2);
    }

    // Collapse to 1 column below md
    @media screen and (max-width: map-get($breakpoints, "md")) {
      flex-basis: 100%;
      max-width: 100%;

      &:nth-child(1):nth-last-child(2),
      &:nth-child(2):nth-last-child(1) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .isomer-card-image {
      img {
        width: 100%;
        height: 12.5rem;
        object-fit: cover;
      }
    }

    .isomer-card-body {
      padding: 1.5rem;

      .isomer-card-title {
        color: $secondary-color;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      .isomer-card-description {
        color: $content-body;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .isomer-card-link {
        font-size: 1rem;
        line-height: 1.5rem;
        text-decoration: underline;
        color: $interaction-link-default;
      }

      .isomer-card-title:has(+ .isomer-card-description) {
        margin-bottom: 0.75rem;
      }

      .isomer-card-title:has(+ .isomer-card-link),
      .isomer-card-description:has(+ .isomer-card-link) {
        margin-bottom: 1.5rem;
      }
    }
  }
}
