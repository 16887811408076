$canvas-base: #ffffff;
$canvas-inverse: #000000;
$canvas-translucent-grey: #00000080;

$content-base: #344054;
$content-body: #484848;
$content-inverse: #ffffff;

$utility-theme-color: $primary-color;
$utility-secondary-color: $secondary-color;

$interaction-hover: #f9f9f9;
$interaction-link-default: #4372d6;
$interaction-link-hover: #3a79ff;
$interaction-support-placeholder: #838894;
$interaction-support: #f4f4f4;

$stroke-default: #d0d5dd;

$border-light: #d6d6d6;
