@use "sass:map";

.textcards-section {
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 1408px) {
    max-width: 1440px;
    margin: auto;
  }
}

.textcards-card-section {
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  
  @media screen and (min-width: 770px) {
    flex-direction: row;
  }

  .textcards-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    flex: 1 0 0;
    align-self: stretch;
    color: #484848;

    border-radius: 4px;
    border: 1px solid $stroke-default;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 0.50);

    @media screen and (min-width: 770px) {
      max-width: 50%;
    }

    @media screen and (min-width: 1280px) {
      max-width: 40%;
    }

    @media screen and (min-width: 770px) and (max-width: 1279px) {
      flex: 0 0 calc(50% - 24px);
    }

    &:hover {
      background-color: $interaction-hover;
      cursor: pointer;
      > .link {
        color: $secondary-color-hover;
      }
    }

    .textcards-card-description {
      flex-grow: 1;
    }
  }
}
